import logo from './logo.svg';
import './App.css';
import bg01 from './img/PXL_20220806_233421701.jpg'
import bg02 from './img/PXL_20220814_203725262.jpg'
import bg03 from './img/PXL_20220821_160445209.jpg'
import bg04 from './img/PXL_20220901_211055851.jpg'
import bg05 from './img/PXL_20220903_155710774.jpg'
import bg06 from './img/PXL_20220903_162102188.jpg'
import bg07 from './img/PXL_20221008_120844188.jpg'
import bg08 from './img/PXL_20221008_172532194.jpg'
import bg09 from './img/PXL_20221008_180544039.jpg'
import bg10 from './img/PXL_20221210_162805822.jpg'
import bg11 from './img/PXL_20221211_145153085.jpg'
import bg12 from './img/PXL_20230317_153956058.jpg'
import bg13 from './img/PXL_20230326_110201421.jpg'
import bg14 from './img/PXL_20230422_111138946.jpg'
import bg15 from './img/PXL_20230423_150408717.jpg'
import bg16 from './img/PXL_20230601_171330135.jpg'

import bgMob01 from './img/20200705_153607.jpg'
import bgMob02 from './img/20210717_130342.jpg'
import bgMob03 from './img/20210725_114700.jpg'
import bgMob04 from './img/20210725_120918.jpg'
import bgMob05 from './img/PXL_20221211_150223880.jpg'

function App() {
  var isMobile = false;
  var pictureArray = []
  if (window.innerWidth<window.innerHeight) {isMobile = true} else {isMobile = false}
  {/*const pictureArray = import.meta.glob('./img/*')*/}
  if(isMobile) {
    pictureArray = [bgMob01,bgMob02,bgMob03,bgMob04,bgMob05]
  }
  else {
    pictureArray = [bg01,bg02,bg03,bg04,bg05,bg06,bg07,bg08,bg09,bg10,bg11,bg12,bg13,bg14,bg15,bg16];
  }
  const randomIndex = Math.floor(Math.random() * pictureArray.length);
  const selectedPicture = pictureArray[randomIndex];
  
  return (
    <div style={{ backgroundImage: `url(${selectedPicture})`, backgroundSize: 'cover' }} className="App">
      <header className="App-header">
        {/*<img src={logo} className="App-logo" alt="logo" /> */}
        {/*<p># b j ö r n i n n</p>*/}
        {/*<p>Mobile: {isMobile.toString()}</p>*/}
        </header>
    </div>
  );
}

export default App;
